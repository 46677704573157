<template>
  <div id="myChart1212" style="height: 300px"></div>
</template>

<script>
// 引入echarts
import * as echarts from "echarts";

export default {
  name: "PcWebsiteIndex",

  mounted() {
    this.drawBar();
  },
  methods: {
    drawBar() {
      this.myChart = echarts.init(document.getElementById("myChart1212"));
      this.myChart.setOption({
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      });
      window.addEventListener("resize", this.myChart.resize);
    },
  },
};
</script>

<style lang="scss" scoped></style>
