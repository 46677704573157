<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar text-center">
      <strong class="fs-1">My Dashboard</strong>
      <div class="menu">
        <div class="alink" @click="toCart">My Cart</div>
        <div class="alink" @click="toOrders">Orders</div>
        <div class="current alink">Account</div>
        <div class="alink" @click="toMessages">Messages</div>
        <div class="alink" @click="toAbout">About</div>
      </div>
    </div>

    <div class="container mt-5 mb-4">
      <div class="member_center">
        <el-alert
          class="mb-3"
          title="Please create your contact information as soon as possible."
          type="info"
          show-icon
          v-if="businessData && businessData.length < 3"
        ></el-alert>

        <div class="myprofile mb-3">
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="block mb-2">
                <el-avatar
                  :size="50"
                  src="https://www.macnn.xyz/pic/avatar.png"
                ></el-avatar>
              </div>

              <el-text class="block mb-3">{{ memberData.username }}</el-text>

              <el-tooltip
                class="item"
                content="Change Password"
                placement="left"
              >
                <el-button class="block" @click="dialogChangePw = true"
                  ><i class="el-icon-key"></i
                ></el-button>
              </el-tooltip>

              <el-tooltip
                class="item"
                content="Click Here to Update Archives"
                placement="bottom"
                :value="businessData.length < 3 ? 'true' : 'false'"
              >
                <el-button class="block" @click="dialogInfoVisible = true"
                  ><i class="el-icon-setting"></i
                ></el-button>
              </el-tooltip>
            </el-col>

            <el-col :span="18">
              <el-row class="ps-3">
                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-user text-warning"></i>Type:
                      {{ memberData.clientTypeTxt }}
                    </el-text>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-star-off text-warning"></i>Level:
                      {{ memberData.levelTxt }}
                    </el-text>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-location-information text-warning"></i
                      >From: {{ memberData.countryName }}</el-text
                    >
                  </div>
                </el-col>
                <el-col :span="24" v-if="businessData.company">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-office-building"></i>Company:
                      {{ businessData.company }}</el-text
                    >
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-s-check text-danger"></i>Job:
                      {{ businessData.job }}</el-text
                    >
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-s-custom text-danger"></i>Name:
                      {{ businessData.name }}</el-text
                    >
                  </div>
                </el-col>

                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-warning-outline text-danger"></i
                      >RegDate: {{ memberData.created_at }}</el-text
                    >
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-mobile-phone text-primary"></i>Tel:
                      {{ businessData.tel }}</el-text
                    >
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-s-data text-primary"></i>TaxCode:
                      {{ businessData.tax }}</el-text
                    >
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block text-left">
                    <el-tooltip
                      content="Calculation method: Amount (completed valid orders)/100"
                      placement="bottom"
                    >
                      <el-text
                        ><i class="el-icon-coin text-primary"></i>Points:
                        N/A</el-text
                      >
                    </el-tooltip>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-position text-info"></i>LastIP:
                      {{ memberData.lastIp }}</el-text
                    >
                  </div>
                </el-col>
                <el-col :span="16">
                  <div class="block text-left">
                    <el-text
                      ><i class="el-icon-time text-info"></i>LastLogin:
                      {{ memberData.lastLoginTime }}</el-text
                    >
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <el-dialog title="New Password" :visible.sync="dialogChangePw">
          <form class="member-password" method="post">
            <div class="input-group mb-4">
              <span class="input-group-text">Current Password</span>
              <input
                type="password"
                class="form-control"
                v-model="passForm.password"
                name="password"
                form-verify="required"
              />
              <p class="invalid-feedback ps-2">
                Leave blank to leave unchanged.
              </p>
            </div>

            <div class="input-group mb-4">
              <span class="input-group-text">New Password</span>
              <input
                type="password"
                class="form-control"
                v-model="passForm.new_password"
                name="new_password"
                form-verify="required"
              />
              <p class="invalid-feedback ps-2">
                Leave blank to leave unchanged.
              </p>
            </div>

            <div class="d-flex flex-row align-items-center mb-0">
              <span class="pe-4 ps-0"
                ><button
                  type="button"
                  class="btn btn-primary"
                  @click="savePassword"
                >
                  Save
                </button></span
              >
            </div>
          </form>
        </el-dialog>

        <!-- el-dialog 对话框 -->
        <el-dialog title="Member Information" :visible.sync="dialogInfoVisible">
          <el-form :model="businessForm" ref="businessForm" label-width="100px">
            <el-form-item label="Company">
              <el-input
                v-model="resData.businessData.company"
                placeholder="Your Company Name"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="Job">
              <el-input v-model="resData.businessData.job"></el-input>
            </el-form-item>
            <el-form-item label="Name">
              <el-input
                v-model="resData.businessData.name"
                placeholder="Your Real Name"
              ></el-input>
            </el-form-item>
            <el-form-item label="Tel">
              <el-input v-model="resData.businessData.tel"></el-input>
            </el-form-item>
            <el-form-item label="TaxCode">
              <el-input
                v-model="resData.businessData.tax"
                placeholder="Your CompanyTax Code"
              ></el-input>
            </el-form-item>
            <el-form-item label="Website">
              <el-input
                v-model="resData.businessData.website"
                placeholder="Your Company Website"
              ></el-input>
            </el-form-item>
            <el-form-item label="Payment">
              <el-select
                v-model="resData.memberData.payWay"
                placeholder="Select your payment method"
                style="width: 100%"
              >
                <el-option
                  v-for="(option, value) in resData.PaymentMethod"
                  :key="value"
                  :label="option"
                  :value="value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Subscription">
              <el-switch
                v-model="resData.memberData.allowNote"
                active-text="Open"
                inactive-text="Closed"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogInfoVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveBusinessInfo">Save</el-button>
          </div>
        </el-dialog>

        <div class="myTotal mb-3">
          <el-row :gutter="20">
            <el-col :span="6">
              <div>
                <el-statistic title="Total Order">
                  <template slot="formatter">
                    <i class="bi-check-all text-success"></i>
                    <count-to
                      :startVal="0"
                      :endVal="memberStats.total_orders"
                      :duration="4000"
                    ></count-to>
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="Total Quanlity">
                  <template slot="formatter">
                    <i class="el-icon-top text-danger"></i>
                    <count-to
                      :startVal="0"
                      :endVal="memberStats.total_order_quantity"
                      :duration="4000"
                    ></count-to>
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="Total Amount">
                  <template slot="formatter">
                    <i class="bi-currency-dollar text-warning"></i
                    ><count-to
                      :startVal="0"
                      :endVal="memberStats.total_order_amount"
                      :duration="4000"
                    ></count-to>
                  </template>
                </el-statistic>
              </div>
            </el-col>

            <el-col :span="6">
              <div>
                <el-statistic title="Total Paid">
                  <template slot="formatter">
                    <i class="bi-currency-dollar text-primary"></i
                    ><count-to
                      :startVal="0"
                      :endVal="memberStats.total_paid_amount"
                      :duration="4000"
                    ></count-to>
                  </template>
                </el-statistic>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="myaddress mb-3">
          <h4 class="mb-3 mb-2">
            My Delivery Address
            <a @click="toggleEditMode">{{
              isEditing ? "Exit Delete" : "Delete"
            }}</a>
          </h4>
          <el-row :gutter="10">
            <el-col
              :span="8"
              v-for="(address, index) in address_list"
              :key="index"
            >
              <div
                class="block"
                @click="!isEditing && !isEditingDialog && editAddress(address)"
              >
                <el-text class="text-primary"
                  >{{ address.aCountryName }}
                  <abbr class="text-right" v-if="address.countNum"
                    >used: {{ address.countNum }}</abbr
                  ></el-text
                >
                <el-text v-if="address.companyName">{{
                  address.companyName
                }}</el-text>
                <el-text>POSTCODE: {{ address.postcode }}</el-text>
                <el-text
                  >ADDRESS: {{ address.aProvince }} {{ address.aCity }},{{
                    address.aDetail
                  }}</el-text
                >
              </div>
              <!-- 显示删除按钮 -->
              <el-button
                v-show="isEditing && !isEditingDialog"
                @click="deleteAddress(index)"
                type="danger"
                icon="el-icon-close"
                class="delete-btn"
                size="mini"
                circle
              ></el-button>
            </el-col>
            <el-col :span="8">
              <div
                class="block text-center"
                :class="{ bgred: !address_list }"
                @click="addAddress"
              >
                <el-button icon="el-icon-plus" circle></el-button>
              </div>
            </el-col>
          </el-row>
        </div>

        <el-dialog
          :visible.sync="dialogVisible"
          :title="isEditing ? 'Edit Address' : 'Add New Address'"
        >
          <el-form :model="editForm" ref="editForm" label-width="90px">
            <el-form-item label="Country">
              <el-input
                v-if="isEditing"
                v-model="editForm.aCountryName"
                disabled=""
              ></el-input>
              <el-select
                v-else
                v-model="editForm.aCountry"
                style="width: 100%"
                filterable
                placeholder="Enter your country's name for a quick search"
              >
                <el-option
                  v-for="item in optionsCountry"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Company">
              <el-input v-model="editForm.companyName"></el-input>
            </el-form-item>

            <div
              class="block mb-3"
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <el-form-item label="FirstName" class="mb-0" style="width: 48%">
                <el-input v-model="editForm.fName"></el-input>
              </el-form-item>
              <el-form-item label="LastName" style="width: 48%">
                <el-input v-model="editForm.lName"></el-input>
              </el-form-item>
            </div>

            <div
              class="block mb-3"
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <el-form-item label="Email" class="mb-0" style="width: 48%">
                <el-input
                  v-model="editForm.email"
                  placeholder="Can be left blank"
                ></el-input>
              </el-form-item>
              <el-form-item label="Phone" style="width: 48%">
                <el-input
                  v-model="editForm.phone"
                  placeholder="Can be left blank"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item label="PostCode">
              <el-input v-model="editForm.postcode" placeholder=""></el-input>
            </el-form-item>
            <!--<el-form-item label="Province">
              <el-input v-model="editForm.aProvince"></el-input>
            </el-form-item>-->
            <el-form-item label="City">
              <el-input v-model="editForm.aCity"></el-input>
            </el-form-item>
            <el-form-item label="Detail">
              <el-input v-model="editForm.aDetail"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveAddress">Save</el-button>
          </div>
        </el-dialog>

        <!-- <ChatOrder></ChatOrder>-->
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import Foot from "@/components/website/Foot.vue";
import ChatOrder from "./chatOrder.vue";
import countTo from "vue-count-to";
import {
  savePassword,
  memberSave,
  memberInfo,
  adressDelete,
  addressEdit,
  addressAdd,
  countryList,
} from "@/api/index.js";

export default {
  name: "PcWebsiteAccount",

  components: {
    SiteHeader,
    Foot,
    ChatOrder,
    countTo,
  },

  data() {
    return {
      resData: "",
      isEditing: false, // 是否处于编辑状态
      DeleteAddressBtn: false,
      dialogVisible: false, // 弹窗是否可见
      editForm: {
        optionsCountry: [],
        aCountry: "", // 存储选中的国家
        value: "",
        aCountryName: "",
        companyName: "",
        postcode: "",
        aProvince: "",
        aCity: "",
        aDetail: "",
        fName: "",
        lName: "",
        email: "",
        phone: "",
      }, // 编辑地址的表单数据

      dialogChangePw: false, // 修改密码弹窗是否可见
      memberData: "",
      businessData: "",
      address_list: "",
      changepassword: true,
      countdown: 0, // 初始化倒计时为0
      passForm: {
        password: "",
        new_password: "",
      },

      dialogInfoVisible: false, // 弹窗是否可见
      businessForm: {
        company: "",
        job: "",
        name: "",
        tel: "",
        tax: "",
        website: "",
        PayWay: "",
        allowNote: "",
      },
    };
  },
  created() {
    // 在组件创建时获取选项列表
    this.fetchCountries();
  },
  computed: {
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
    userinfo: {
      get() {
        return this.$store.state.user.userinfo;
      },
    },
  },
  watch: {},
  mounted() {
    this.getDetail();
  },
  methods: {
    //save password
    savePassword() {
      const params = {
        password: this.passForm.password,
        new_password: this.passForm.new_password,
      };
      if (
        this.passForm.password !== "" &&
        this.passForm.password !== this.passForm.new_password
      ) {
        savePassword(params).then((res) => {
          this.$message({
            message: "change password success",
            type: "success",
          });
          this.dialogChangePw = false;
        });
      } else {
        this.$message({
          message: "Password and new password must be different",
          type: "warning",
        });
      }
    },

    openDialogInfo() {
      // 在打开对话框时将 memberData 数据追加到表单中
      this.businessForm = { ...this.businessForm, ...this.resData };
      this.dialogInfoVisible = true; // 打开对话框
    },
    saveBusinessInfo() {
      // 过滤需要保留的参数和值
      const filteredData = {
        company: this.resData.businessData.company,
        job: this.resData.businessData.job,
        name: this.resData.businessData.name,
        tel: this.resData.businessData.tel,
        tax: this.resData.businessData.tax,
        website: this.resData.businessData.website,
      };

      // 检查并添加支付方式
      if (this.resData.memberData.payWay !== null) {
        filteredData.payWay = this.resData.memberData.payWay;
        filteredData.allowNote = this.resData.memberData.allowNote;
      }
      //console.log('PayWay:', this.resData.memberData.payWay);

      // 调用保存方法，并将过滤后的数据作为参数传递给它
      memberSave(filteredData)
        .then((response) => {
          // 如果接口调用成功
          if (response.code === 1) {
            // 显示成功提示信息
            this.$message.success("Member Information saved successfully");
            // 关闭弹窗
            this.dialogInfoVisible = false;
            this.getDetail();
          } else {
            // 显示失败提示信息
            this.$message.error(response.message || "Failed to save address");
          }
        })
        .catch((error) => {
          // 如果接口调用失败，显示失败提示信息
          this.$message.error(error.message || "Failed to save address");
        });
    },

    toggleEditMode() {
      this.isEditing = !this.isEditing;
    },

    deleteAddress(index) {
      const addressId = this.address_list[index].id;
      adressDelete({ id: addressId })
        .then(() => {
          // 删除成功后从 address_list 中移除对应的地址
          this.address_list.splice(index, 1);
          this.$message.success("Address deleted successfully");
          this.getDetail();
        })
        .catch((error) => {
          this.$message.error("Failed to delete address: " + error.message);
        });
    },
    editAddress(address) {
      // 编辑地址逻辑
      this.isEditing = true; // 设置为编辑模式
      this.editForm = { ...address }; // 将地址信息填充到编辑表单中
      this.dialogVisible = true; // 打开弹窗
      //this.$message.info('Address editing is not implemented yet');
    },
    fetchCountries() {
      countryList()
        .then((response) => {
          // 将接口返回的数据赋值给 options
          this.optionsCountry = response.data;
        })
        .catch((error) => {
          console.error("Failed to fetch countries: ", error);
        });
    },
    addAddress() {
      this.fetchCountries();
      this.editForm = {
        aCountryName: "",
        companyName: "",
        postcode: "",
        aProvince: "",
        aCity: "",
        aDetail: "",
        fName: "",
        lName: "",
        email: "",
        phone: "",
      };
      this.isEditing = false; // 设置为编辑模式以显示弹窗
      this.dialogVisible = true; // 打开添加地址的弹窗
    },
    saveAddress() {
      // 根据当前编辑模式选择调用编辑或新增地址的接口
      const saveApi = this.isEditing ? addressEdit : addressAdd;

      saveApi(this.editForm)
        .then((response) => {
          // 如果接口调用成功
          if (response.code === 1) {
            // 显示成功提示信息
            this.$message.success("Address saved successfully");
            // 关闭弹窗
            this.dialogVisible = false;
            this.getDetail();
          } else {
            // 显示失败提示信息
            this.$message.error(response.message || "Failed to save address");
          }
        })
        .catch((error) => {
          // 如果接口调用失败，显示失败提示信息
          this.$message.error(error.message || "Failed to save address");
        });
    },
    getDetail() {
      memberInfo()
        .then((res) => {
          this.resData = res.data;
          this.memberData = this.resData.memberData;
          this.businessData = this.resData.businessData;
          this.address_list = this.resData.address_list;
          this.memberStats = this.resData.MemberStats;
        })
        .catch((error) => {
          // 如果无法获取用户信息（例如 token 过期），则将用户重定向到登录页面
          console.error("Failed to get user information:", error);
          this.$router.push("/login");
        });
    },

    toOrders() {
      this.$router.push("/order/list");
    },
    toCart() {
      this.$router.push("/cart");
    },
    toAccount() {
      this.$router.push("/account");
    },
    toMessages() {
      this.$router.push("/message");
    },
    toAddress() {
      this.$router.push("/about");
    },
    toAbout() {
      this.$router.push("/about");
    },
    tologin() {
      this.$router.push("/login");
    },

    //跳到外链
    towindowurl() {
      window.location.href = this.banner.link;
    },

    init() {
      // alert(this.site.id)
      detail({ site_id: this.site.id }).then((res) => {
        this.banner = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.member_center {
  justify-items: center;
  align-items: center;
  font-size: 0.875rem;
  color: var(--el-text-color-primary);
  .myprofile {
    padding: 4rem 1rem;
    text-align: center;
    justify-items: center;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--el-color-primary-light-9);

    .el-col {
      margin-bottom: 10px;
      .block {
        font-size: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .el-col-18,
    .el-col-6 {
      margin-bottom: 0px;
    }

    i {
      margin-right: 3px;
    }
  }
  .myTotal,
  .myaddress,
  .item_news {
    background-color: var(--el-bg-color-page);
    padding: 1rem 1.5rem;
    h4 {
      display: block;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      a {
        display: inline-block;
        float: right;
        color: var(--el-color-primary);
      }
    }
  }
  .el-statistic {
    .head,
    .con span {
      color: var(--el-text-color-primary);
    }
  }

  .myaddress,
  .item_news {
    padding-bottom: 10px;
    .el-col {
      margin-bottom: 10px;
      position: relative;
      .bgred {
        background-color: var(--el-color-error-light-5) !important;
      }
      .block {
        padding: 1rem;
        border: 1px solid var(--el-border-color);
        background-color: var(--el-bg-color);
        height: 140px;
        cursor: pointer;
        vertical-align: top;
        overflow-y: hidden;
      }
      .block:last-child {
        vertical-align: middle;
        align-content: center;
      }
      .block:hover {
        background-color: var(--el-color-primary-light-9);
        border: 1px solid var(--el-color-primary-light-8);
        color: var(--el-color-black);
      }
      .delete-btn {
        position: absolute;
        left: -2px;
        top: -3px;
        z-index: 10;
      }
    }
    el-text {
      display: block;
    }
  }

  .item_news {
    .block {
      vertical-align: middle;
      align-content: center;

      .el-avatar {
        float: left;
      }
      el-text {
        float: right;
        display: inline-block;
        margin-bottom: 5px;
      }
    }
  }

  .col-md-12 {
    display: flex;
    justify-items: center;
    el-text {
      font-size: 0.875rem;
      display: inline-block;
      flex: 1;
      padding: 5px;
      text-align: center;
      i {
        margin-right: 5px;
      }
    }
  }
}
</style>
<style>
.el-dialog__body {
  padding: 20px;
  .el-form-item:last-child {
    margin-bottom: 0px;
  }
}
</style>
